import React, { Component } from "react";

class TwoTiersWithExtraTier extends Component {
  render() {
    return (
<div className="bg-white">
  <div className="pt-12">
    <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto lg:max-w-none">
        <h1 className="mt-2 text-3xl leading-9 font-extrabold text-grey-600 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
          Every Share Price Online
        </h1>
        <p className="mt-2 text-xl leading-7 text-gray-500">
          Up to date share prices and market data to help you make better trading decisions.
        </p>
      </div>
    </div>
  </div>
  <div className="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
    <div className="relative">
      <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-md mx-auto lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5">
          <div className="rounded-lg shadow-lg overflow-hidden">
            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
              <div>
                <span className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-teal-50 text-teal-400">
                  Ireland
                </span>
              </div>
              <div className="mt-4 flex items-baseline text-4xl leading-none font-extrabold">
                Irish Share Prices
              </div>
              <p className="mt-5 text-lg leading-7 text-gray-500">
                Get the latest share prices from the Euronext Dublin (ISEQ/Irish Stock Exchange)
              </p>
            </div>
            <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
              <div className="mt-2 rounded-md shadow">
                <a href="/markets/euronext-dublin/" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Check latest prices
                </a>
              </div>
            </div>
          </div>
          <div className="mt-4 rounded-lg shadow-lg overflow-hidden lg:mt-0">
            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
              <div>
                <span className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-teal-50 text-teal-400">
                  UK
                </span>
              </div>
              <div className="mt-4 flex items-baseline text-4xl leading-none font-extrabold">
                FTSE Share Prices
              </div>
              <p className="mt-5 text-lg leading-7 text-gray-500">
                Get the latest share prices from the London Stock Exchange (FTSE and AIM)
              </p>
            </div>
            <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
              <div className="mt-2 rounded-md shadow">
                <a href="/markets/london-stock-exchange/" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Check latest prices
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-12 relative max-w-screen-xl mx-auto px-4 sm:px-6">
      <div className="max-w-md mx-auto lg:max-w-5xl">
        <div className="rounded-lg bg-gray-50 px-6 py-8 sm:p-10 lg:flex lg:items-center">
          <div className="flex-1">
            <div>
              <span className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-white text-gray-800">
                Crypto (coming soon)
              </span>
            </div>
            <div className="mt-4 text-lg leading-7 text-gray-500">
              Get the latest prices, bids, offers, and volumes for over 500 cryptocurrencies
            </div>
          </div>
          <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
            <a href="/crypto/" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Get notified
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



    );
  }
}

export default TwoTiersWithExtraTier;

import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";

{/* FeatureSections*/}
import TwoTiersWithExtraTier from "../components/PricingSections/TwoTiersWithExtraTier";




class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`Every Share Price: Master The Markets | ${config.siteTitle}`} />  



          {/* FeatureSections*/}
          <TwoTiersWithExtraTier />





        </div>
      </Layout>
    );
  }
}

export default IndexPage;
